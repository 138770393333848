.transform-component-module_wrapper__SPB86 {
  height: 100% !important;
  width: 100% !important;
  overflow: hidden;
}
.transform-component-module_content__FBWxo {
  height: 100% !important;
  width: 100% !important;
  overflow: hidden;
}
