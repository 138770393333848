.non-editable .ql-editor {
  padding: 0px 0px;
}

.ql-editor {
  color: #19191a;
  font-family: Inter-Regular;
  font-size: 14px;
  line-height: 20px;
}

.dark-mode .ql-editor {
  color: #fff;
}

.dark-mode .ql-toolbar.ql-snow {
  border-bottom: 1px solid #38383a;
}
.dark-mode .ql-editor.ql-blank::before {
  color: #9e9e9e;
}

.ql-mention-list-item {
  padding: 0px;
  margin: 0px;
}

.mention {
  background-color: transparent;
  padding: 0px;
}

.mention > span {
  color: #007aff;
}

.ql-snow .ql-editor img {
  max-width: 200px;
}
