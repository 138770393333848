.pulse {
  animation: pulse-animation 1.5s infinite;
  box-shadow: 0px 0px 1px 1px #57db62;
  border-radius: 50%;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0 rgba(84, 212, 95, 0.3);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(84, 212, 95, 0);
  }
}
