.jsoneditor {
  border: 1px solid #e8ebed;
  border-radius: 8px;
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0;
  line-height: 100%;
}

.jsoneditor-menu {
  background-color: #e8ebed;
  border-bottom-color: #d4d7d9;
  padding: 4px;
  display: flex;
  align-items: center;
  height: 40px;
}

.jsoneditor-menu > button,
.jsoneditor-menu > .jsoneditor-modes > button {
  border-radius: 2px;
  border: 1px solid transparent;
  background-color: #939ba9;
}

.jsoneditor-statusbar {
  background-color: #e8ebed;
  border-top-color: #d4d7d9;
  color: #2b3443;
  padding: 4px;
  display: flex;
  align-items: center;
  height: 40px;
}

div.jsoneditor-field,
div.jsoneditor-value,
div.jsoneditor td,
div.jsoneditor th,
div.jsoneditor textarea,
pre.jsoneditor-preview,
.jsoneditor-schema-error,
.jsoneditor-popover {
  font-size: 14px;
  color: #2b3443;
  background-color: transparent;
}

.dark-mode .jsoneditor {
  border: 1px solid #333438;
}

.dark-mode .jsoneditor-menu {
  background-color: #333438;
  border-bottom-color: #373a3e;
}

.dark-mode .jsoneditor-menu > button,
.dark-mode .jsoneditor-menu > .jsoneditor-modes > button {
  background-color: #8d929b;
}

.dark-mode .jsoneditor-statusbar {
  background-color: #333438;
  border-top-color: #373a3e;
  color: #fafafa;
}

.dark-mode div.jsoneditor-field,
.dark-mode div.jsoneditor-value,
.dark-mode div.jsoneditor td,
.dark-mode div.jsoneditor th,
.dark-mode div.jsoneditor textarea,
.dark-mode pre.jsoneditor-preview,
.jsoneditor-schema-error,
.jsoneditor-popover {
  color: #fafafa;
}
