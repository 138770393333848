body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::placeholder {
  color: #7d7d82;
}

::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: #e5e5ea;
  border-radius: 20px;
}

.react-flow__handle {
  visibility: hidden;
  width: 0;
  height: 0;
}

.react-flow__node-default {
  padding: 0px !important;
  border-radius: 6px !important;
  width: 240px !important;
  border-color: transparent !important;
  background-color: white;
  border-width: 0px !important;
}
.react-flow__panel.top {
  display: flex;
  position: absolute;
  right: 0;
  left: 0;
  margin: 0;
}

.react-flow__node-default.selectable.selected,
:focus {
  box-shadow: 0 0 0 0 !important;
}

.react-flow__controls-button:disabled {
  opacity: 0.5;
  pointer-events: auto !important;
  cursor: not-allowed;
}

/* Default font option */
.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: 'Sans Serif';
}

/* Monospace option */
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
  content: 'Monospace';
  font-family: 'monospace';
}

/* Poppins Regular */
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="Poppins-Regular"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Poppins-Regular"]::before {
  content: 'Poppins Regular';
  font-family: 'Poppins-Regular';
}

/* Poppins Medium */
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="Poppins-Medium"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Poppins-Medium"]::before {
  content: 'Poppins Medium';
  font-family: 'Poppins-Medium';
}

/* Poppins SemiBold */
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="Poppins-SemiBold"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Poppins-SemiBold"]::before {
  content: 'Poppins SemiBold';
  font-family: 'Poppins-SemiBold';
}

/* Poppins Bold */
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="Poppins-Bold"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Poppins-Bold"]::before {
  content: 'Poppins Bold';
  font-family: 'Poppins-Bold';
}


@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
