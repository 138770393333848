.three-dots-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content; /* Adjust width as needed */
}

.dot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #83cb34; /* Adjust color as needed */
  margin: 0 2px;
  animation: dot-animation 1.2s infinite ease-in-out;
}

@keyframes dot-animation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
